/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
    event.preventDefault(); 
    event.stopPropagation(); 
    $(this).parent().siblings().removeClass('open');
    $(this).parent().toggleClass('open');
});

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var minloadingtime = 0
        var maxloadingtime = 300

        var startTime = new Date()
        var elapsedTime
        var dismissonloadfunc, maxloadingtimer

        window.addEventListener('load', dismissonloadfunc = function(){ // when page loads
            clearTimeout(maxloadingtimer) // cancel dismissal of transition after maxloadingtime time
            elapsedTime = new Date() - startTime // get time elapsed once page has loaded
            var hidepageloadertimer = (elapsedTime > minloadingtime)? 0 : minloadingtime - elapsedTime

            setTimeout(function(){
                document.getElementById('pageloader').classList.add('dimissloader') // dismiss transition
            }, hidepageloadertimer)

        }, false)

        maxloadingtimer = setTimeout(function(){ // force dismissal of page transition after maxloadingtime time
            window.removeEventListener('load', dismissonloadfunc, false) // cancel onload event function call
            document.getElementById('pageloader').classList.add('dimissloader') // dismiss transition
        }, maxloadingtime)
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(function() {

          //$('.flex-images').flexImages({rowHeight: 200});

          if($('.isotope').length){
            // init Isotope
            var $grid = $('.isotope').isotope({
              // options...
              itemSelector: '.grid-item',
              percentPosition: true,
              masonry: {
                columnWidth: '.grid-sizer'
              },
              getSortData: {
                order: '[data-orderby] parseInt'
              },
              sortBy : 'order',
              sortAscending: true
            });
            // layout Isotope after each image loads
            $grid.imagesLoaded().progress( function() {
              $grid.isotope('layout');
            });
          }

          if($('.matchHeight').length){
            $('.matchHeight').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            });
          }

          $('[data-toggle="tooltip"]').tooltip();

        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Search Bar
        var searchNavItem = $('.search-nav-item');
        var searchBar = $('#search-bar');
        var searchBarInput = searchBar.find('input');
        var searchBarBtn = searchBar.find('.btn');
        var searchBarForm = searchBar.find('form');

        searchNavItem.on('click', function(e){
          e.preventDefault();
          e.stopPropagation();
          showSearchBar();
        });

        function showSearchBar(){
          searchBar.show("slow");
          searchBarInput.focus();
        }

        searchBarForm.on("focusout focusin", "input", function(e){
          if ( e.type == "focusout" ) {
            setTimeout(function() {
                searchBar.hide("slow");
            }, 500);
          }
        });
        //Search Bar [END]

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'blog': {
      init: function() {
        // JavaScript to be fired on the home page

        // Search Bar
        var searchNavItem = $('.search-nav-item');
        var searchBar = $('#search-bar');
        var searchBarInput = searchBar.find('input');
        var searchBarBtn = searchBar.find('.btn');
        var searchBarForm = searchBar.find('form');

        searchNavItem.on('click', function(e){
          e.preventDefault();
          e.stopPropagation();
          showSearchBar();
        });

        function showSearchBar(){
          searchBar.show("slow");
          searchBarInput.focus();
        }

        searchBarForm.on("focusout focusin", "input", function(e){
          if ( e.type == "focusout" ) {
            setTimeout(function() {
                searchBar.hide("slow");
            }, 500);
          }
        });
        //Search Bar [END]

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
